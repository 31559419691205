@font-face {
  font-family: conduit;
  src: url(./fonts/conduitITCmediumRegularFont.ttf);
}

body
{
  background-color: #000000;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  font-family: conduit, serif;
  font-weight: normal;            /* normal, bold */
  font-style: normal;             /* normal, italic */
  font-variant: normal;           /* normal, small-caps */
  font-size: 18px;
  line-height: 22px;
  color: #000000;                 /* hexadecimal RGB color code */
  text-decoration: none;          /* none, underline, overline, line-through */
  text-align: left;               /* left, right, center, justify */
}

.arscene {
  height: 100%;
  width: 100%;
}

.error-message {
  color: red;
}

.debug-button {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1000;
}

.fancy
{
  min-height: 150px;
  /* next two lines for elder IE compatibility */
  height: auto !important;
  height: 150px;

  /* Background color and gradients */
  background: #eeeeee;
  background: -moz-linear-gradient(top, #ffffff, #cccccc);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ffffff), to(#cccccc));
  /* really nifty translucence */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.8), rgba(200, 200, 200, 0.8) );
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from( rgba(255, 255, 255, 0.8) ), to( rgba(200, 200, 200, 0.8) ));

  /* Rounded corners */
  -moz-border-radius: 15px 15px 15px 15px;
  -webkit-border-radius: 15px 15px 15px 15px;
  border-radius: 15px 15px 15px 15px;
}

/* Page text links */
a
{
  font-size: 20px;
  font-weight: bold;
  color:#00c;
  text-shadow: 1px 1px 0px #aaa;
  margin: 13px 0px 0px 0px;
  text-decoration: none;

  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.toc
{
  font-size: 16px;
}

a:hover
{
  color:#c00;
  /*text-shadow: 1px 1px 0px #f00;
  /* gradual change */
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.superText
{
  color: #000;
  text-shadow: 1px 1px 0px #aaa;
}

a .superImage
{
  height: 150px;
  width: 225px;
  border: 1px solid #000000;
  margin: 0px 20px 0px 0px;
  float:left;


  /* Rounded corners */
  -moz-border-radius: 15px 15px 15px 15px;
  -webkit-border-radius: 15px 15px 15px 15px;
  border-radius: 15px 15px 15px 15px;

  position: relative;

  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

a:hover .superImage
{
  top: -10px;

  /* fallback on standard opaque colors */
  -moz-box-shadow: 10px 10px 5px #888;
  -webkit-box-shadow: 10px 10px 5px #888;
  box-shadow: 10px 10px 5px #888;
  /* translucent: the way shadows should be */
  -moz-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);

  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.bigTitle
{
  font-size: 32px;
  font-weight: bold;
  color:#004;
  text-align: center;
  text-shadow: -1px -1px 2px #fff, 1px 1px 3px #000;
}

.splash-screen {
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: #000000;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  font-family: conduit, serif;
  color: white;
  padding: 108px 2.5rem;
  box-sizing: border-box;
}

.splash-title {
  font-size: calc(min(7.6vw, 32px));;
  font-weight: bold;
  color: #8cd8a0;
  font-family: conduit, serif;
  margin-bottom: 20px;
  letter-spacing: 10px;
  line-height: 1.2em;
  text-align:justify;
}

button {
  font-size: 15.75px;
}

.splash-text {
  font-size: 1.025em;
  font-weight: lighter;
  color: #ffffff;
  font-family: conduit, serif;
  margin: 0 0 26px;
  padding: 0;
}

.splash-button {
  background-color: #8cd8a0;
  color: black;
  border-radius: 20px;
  height: 32px;
  width: 122px;
  font-size: 0.875em;
  font-family: conduit, serif;
  font-weight: 700;
  box-shadow: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  line-height: 19px;
  letter-spacing: 0.07em;
}

.centered-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.hint-screen {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: var(--app-height);
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.hint-container {
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  flex-direction: column;
  position: relative;
  aspect-ratio: 1 / 1;
  max-height: calc(var(--app-height) - 50px);
}

.hint-text {
  padding-top: 10px;
  max-width: 60vw;
  color: black;
  font-weight: bold;
  font-family: conduit, serif;
  font-size: 1.025em;
  text-align: center;
  line-height: 1.2em;
}

.hint-next-button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color:  #8cd8a0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%);
  box-shadow: none;
  border: none;
}

.rotate-90 {
  transform: rotate(90deg);
}

/*https://github.com/aframevr/aframe/blob/master/src/style/aframe.css*/
.a-dialog {
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  border-radius: 20px;
}

.a-dialog-allow-button {
  background-color:  #8cd8a0;
  color: #000000;
  border-radius: 20px;
  height: 40px;
  font-size: 16px;
  font-family: conduit, serif;
}

.a-dialog-deny-button {
  background-color: rgb(239, 128, 96);
  color: black;
  border-radius: 20px;
  height: 40px;
  font-size: 16px;
  font-family: conduit, serif;
}

.a-dialog-ok-button {
  background-color:  #8cd8a0;
  color: black;
  border-radius: 20px;
  height: 40px;
  font-size: 16px;
  font-family: conduit, serif;
}

.linear-progress-root {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: rgb(44, 86, 60);
}

@keyframes linear-progress-bar1-anim {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

.linear-progress-bar1 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  transition: transform 0.2s linear 0s;
  transform-origin: left center;
  background-color: rgba(114, 218, 155, 0.8);
  width: auto;
  animation: 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running linear-progress-bar1-anim;
}

@keyframes linear-progress-bar2-anim {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.linear-progress-bar2 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  transition: transform 0.2s linear 0s;
  transform-origin: left center;
  background-color: rgba(114, 218, 155, 0.8);
  width: auto;
  animation: 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite normal none running linear-progress-bar2-anim;
}

.landscape-hint {
  display: none;
}

@media screen and (orientation:landscape) {
  .landscape-hint {
    display: flex;
  }
}


text {
  font-family: conduit, serif;
  color: #8cd8a0;
}

svg {
  width: 100%;
}

.nav-menu-root {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  width: 80px;
  height: 80px;
  overflow-x: visible;
  overflow-y: visible;
}

.nav-menu-container {
  position: relative;
  width: 300%;
  transition: transform 0.5s ease;
  transform: translateX(0%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 0.8);
}

.nav-button-active {
  transition: transform 0.5s ease;
  transform: scale(1.2);
  margin: 16px;
}

.nav-button-active::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  border: 5px solid #ffffff;
  border-radius: 75px;
  height: 65px;
  width: 65px;
  content: '';
}

.nav-button-landmarks {
  background-color:  #8cd8a0
}

.nav-button-landmarks {
  background-color:  #8cd8a0
}

.nav-button-cities {
  background-color: #F2DB55;
}

.nav-button-tubes {
  background-color: #EF8060;
}

.nav-button-tubes {
  background-color: #EF8060;
}

.nav-button-panorama {
  background-color:  #8cd8a0
}

.nav-button-camera {
  background-color:  #8cd8a0
}